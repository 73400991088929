<template>
  <div class="modal fade" :id="type" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ title }}</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <el-form :model="modalForm" ref="modalForm" size="mini">
            <el-row>
              <el-col :span="3">
                <div class="text-dark">Төлөх огноо</div>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  v-for="(input, index) in modalForm.inputs"
                  :key="input.dateKey"
                  :prop="'inputs.' + index + '.dateValue'"
                >
                  <el-date-picker
                    placeholder="YYYY-MM-DD - Төлөх огноо"
                    type="date"
                    size="mini"
                    v-model="input.dateValue"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="1" />
              <el-col :span="3">
                <div class="text-dark">Төлөх огноо</div>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  v-for="(input, index) in modalForm.inputs"
                  :key="input.amountKey"
                  :prop="'inputs.' + index + '.amountValue'"
                >
                  <div class="el-input el-input--mini" style="width: 50%">
                    <VueNumberFormat
                      class="el-input__inner"
                      v-model:value="input.amountValue"
                    ></VueNumberFormat>
                  </div>
                  &nbsp;
                  <el-button
                    type="info"
                    icon="el-icon-delete"
                    round
                    plain
                    @click="removeDomain(input)"
                    size="mini"
                    :disabled="index === 0"
                    :hidden="index === 0"
                  ></el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="separator my-1"></div>
        <div class="row" style="padding: 10px 20px 10px 10px;">
          <el-row type="flex" justify="space-between">
            <template
              v-if="
                modalForm.inputs[modalForm.inputs.length - 1].dateValue
                  .length !== 0 &&
                  modalForm.inputs[modalForm.inputs.length - 1].amountValue
                    .length !== 0
              "
            >
              <el-button
                type="text"
                @click="addDomain"
                icon="el-icon-plus"
                class="col-lg-3"
                size="mini"
                >Мөр нэмэх
              </el-button>
            </template>
            <template v-else>
              <div class="col-lg-3"></div>
            </template>
            <el-col class="col-lg-2" />
            <button
              plain
              size="mini"
              class="el-button el-button--success el-button--mini is-plain col-lg-3"
              @click="addDetails"
              :data-kt-indicator="this.buttonLoader"
            >
              <span class="indicator-label">
                Хадгалах
              </span>
              <span class="indicator-progress">
                Түр хүлээнэ үү...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <el-button
              type="info"
              plain
              size="mini"
              class="col-lg-3"
              :id="'modalBackButton' + this.type"
              data-bs-dismiss="modal"
              >Болих
            </el-button>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "loan-details-payment",
  emits: ["reloadLoanDetails"],
  props: ["title", "type", "loancode"],
  data() {
    return {
      buttonLoader: "off",
      modalForm: {
        inputs: [
          {
            dateKey: Date.now(),
            dateValue: "",
            amountKey: Date.now() + 1,
            amountValue: ""
          }
        ]
      }
    };
  },
  methods: {
    async addDetails() {
      this.dataLoading = true;
      this.buttonLoader = "on";
      const list = this.modalForm.inputs.map(item => {
        return {
          dateValue: item.dateValue,
          amountValue: item.amountValue
        };
      });
      const data = {
        list: list,
        len: this.modalForm.inputs.length,
        type: this.type,
        loancode: this.loancode
      };
      await ApiService.postWithRawBody("loandetails", { data })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              document.getElementById("modalBackButton" + this.type).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
          this.buttonLoader = "off";
          this.modalForm.inputs = [
            {
              dateKey: Date.now(),
              dateValue: "",
              amountKey: Date.now() + 1,
              amountValue: ""
            }
          ];
        });
    },

    addDomain() {
      this.modalForm.inputs.push({
        dateKey: Date.now(),
        dateValue: "",
        amountKey: Date.now() + 1,
        amountValue: ""
      });
    },
    removeDomain(item) {
      const index = this.modalForm.inputs.indexOf(item);
      if (index !== -1) {
        this.modalForm.inputs.splice(index, 1);
      }
    }
  }
});
</script>
