
import { defineComponent, ref } from "vue";
import { ElButton, ElForm, ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "loan-details-relation",
  emits: ["reloadLoanDetails"],
  props: ["loancode", "customercode"],
  setup() {
    const citizenFormRef = ref<InstanceType<typeof ElForm>>();
    const orgFormRef = ref<InstanceType<typeof ElForm>>();
    const submitOrgRef = ref<InstanceType<typeof ElButton>>();
    const submitCustRef = ref<InstanceType<typeof ElButton>>();

    const formSubmitOrg = () => {
      orgFormRef.value?.validate(async valid => {
        if (valid) submitOrgRef.value?.$el.click();
        else return false;
      });
    };

    const formSubmitCust = () => {
      citizenFormRef.value?.validate(async valid => {
        if (valid) submitCustRef.value?.$el.click();
        else return false;
      });
    };

    const clearFieldOrg = () => {
      orgFormRef.value?.resetFields();
    };

    const clearFieldCust = () => {
      citizenFormRef.value?.resetFields();
    };

    return {
      citizenFormRef,
      orgFormRef,
      submitCustRef,
      submitOrgRef,
      formSubmitOrg,
      formSubmitCust,
      clearFieldOrg,
      clearFieldCust
    };
  },
  data() {
    return {
      buttonLoaderOrg: "off",
      buttonLoaderCitizen: "off",
      modalCitizenForm: {
        isForeign: "1",
        surname: "",
        givenname: "",
        registerno: ""
      },
      modalCitizenFormRule: {
        surname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        givenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: [
          {
            required: true,
            message: "Хоосон байна.",
            trigger: "blur"
          },
          {
            len: 10,
            message: "Буруу байна.",
            trigger: "blur"
          }
        ]
      },
      modalOrgForm: {
        isForeign: "1",
        givenname: "",
        stateregisterno: "",
        registerno: ""
      },
      modalOrgFormRule: {
        givenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async formSubmitOrgHidden() {
      this.buttonLoaderOrg = "on";
      const data = {
        isforeign: this.modalOrgForm.isForeign,
        givenname: this.modalOrgForm.givenname,
        stateregisterno: this.modalOrgForm.stateregisterno,
        registerno: this.modalOrgForm.registerno,
        type: "relationOrg",
        loancode: this.loancode,
        customercode: this.customercode
      };
      await ApiService.postWithRawBody("loandetails", { data })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              (document.getElementById(
                "modalBackButtonRelationOrg"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.buttonLoaderOrg = "off";
        });
    },
    async formSubmitCitizenHidden() {
      this.buttonLoaderCitizen = "on";
      const data = {
        isforeign: this.modalCitizenForm.isForeign,
        givenname: this.modalCitizenForm.givenname,
        surname: this.modalCitizenForm.surname,
        registerno: this.modalCitizenForm.registerno,
        type: "relationCitizen",
        loancode: this.loancode,
        customercode: this.customercode
      };
      await ApiService.postWithRawBody("loandetails", { data })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              (document.getElementById(
                "modalBackButtonRelationCitizen"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.buttonLoaderCitizen = "off";
        });
    }
  }
});
