
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "loan-details-mort",
  props: [
    "loancode",
    "customercode",
    "mrtType1",
    "mrtType2",
    "currencies",
    "transferReason",
    "authImmovable"
  ],
  emits: ["reloadLoanDetails"],
  setup() {
    const formRef = ref<InstanceType<typeof ElForm>>();
    const submitRef = ref<InstanceType<typeof ElButton>>();

    const formSubmit = () => {
      formRef.value?.validate(async valid => {
        if (valid) submitRef.value?.$el.click();
        else return false;
      });
    };

    const clearField = () => {
      formRef.value?.resetFields();
    };

    return {
      formRef,
      submitRef,
      formSubmit,
      clearField
    };
  },
  computed: {
    filteredMrtType2(): unknown {
      return this.mrtType2.filter(item => {
        return this.mortData.mrtType1 === item.mrtcode;
      });
    }
  },
  data() {
    return {
      loadButton: "off",
      mortData: {
        type: "0",
        uniquenumber: "",
        mrtType1: "",
        mrtType2: "",
        zipcode: "",
        address: "",
        desc: "",
        dateofvaluation: "",
        currency: "",
        limit: "",
        mrtvalue: "",
        selectCitizenOrg: "0",
        selectSocial: "1",
        surname: "",
        givenname: "",
        registerno: "",
        stateregisterno: "",
        authImmovable: "01",
        mrtstateregisterno: "",
        mrtconfirmeddate: "",
        mrtcertificateno: "",
        mrtregistereddate: "",
        transferreason: "",
        courtorderdate: "",
        courtorderno: ""
      },
      mortDataRule: {
        uniquenumber: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtType1: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtType2: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        desc: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        dateofvaluation: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        currency: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        limit: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtvalue: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        surname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        givenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        stateregisterno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtstateregisterno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtconfirmeddate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        mrtregistereddate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        courtorderdate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        courtorderno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        address: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        }
      }
    };
  },

  methods: {
    async saveFormData() {
      this.loadButton = "on";
      const data = {
        type: "addMortgage",
        loancode: this.loancode,
        customercode: this.customercode,
        mortType: this.mortData.type,
        uniquenumber: this.mortData.uniquenumber,
        mrtType1: this.mortData.mrtType1,
        mrtType2: this.mortData.mrtType2,
        zipcode: this.mortData.zipcode,
        address: this.mortData.address,
        desc: this.mortData.desc,
        dateofvaluation: this.mortData.dateofvaluation,
        currency: this.mortData.currency,
        limit: this.mortData.limit,
        mrtvalue: this.mortData.mrtvalue,
        selectCitizenOrg: this.mortData.selectCitizenOrg,
        selectSocial: this.mortData.selectSocial,
        surname: this.mortData.surname,
        givenname: this.mortData.givenname,
        registerno: this.mortData.registerno,
        stateregisterno: this.mortData.stateregisterno,
        authImmovable: this.mortData.authImmovable,
        mrtstateregisterno: this.mortData.mrtstateregisterno,
        mrtconfirmeddate: this.mortData.mrtconfirmeddate,
        mrtcertificateno: this.mortData.mrtcertificateno,
        mrtregistereddate: this.mortData.mrtregistereddate,
        transferreason: this.mortData.transferreason,
        courtorderdate: this.mortData.courtorderdate,
        courtorderno: this.mortData.courtorderno
      };
      await ApiService.postWithRawBody("loandetails", { data })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              (document.getElementById(
                "modalBackButtonMortModal"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    }
  }
});
