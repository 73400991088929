
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import LoanDetailsPayment from "@/components/modals/loanDetailsPaymentModal.vue";
import LoanDetailsRelation from "@/components/modals/loanDetailsRelationModal.vue";
import LoanDetailsMort from "@/components/modals/loanDetailsMortModal.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "LoanDetails",
  components: {
    LoanDetailsMort,
    LoanDetailsRelation,
    LoanDetailsPayment
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээлийн дэлгэрэнгүй");
    });
  },
  mounted() {
    this.getLoanDetails();
  },
  data() {
    const idArr = this.b64DecodeUnicode(this.$route.params.id)
      .toString()
      .split("@");
    const loancode = idArr[0];
    const orgcode = idArr[1];
    const customercode = idArr[2];
    const version = process.env.VUE_APP_LOAN_FORM_EL_ALERT_DESC;

    return {
      version,
      loancode,
      customercode,
      orgcode,
      dataLoading: false,
      authImmovable: [],
      currencies: [],
      customerRelationCitizen: [],
      customerRelationOrg: [],
      interestDetails: [],
      interestPerformance: [],
      loanDetails: [],
      loanPerformance: [],
      mortgage: [],
      mrtType1: [],
      mrtType2: [],
      transferReason: []
    };
  },
  methods: {
    async getLoanDetails() {
      this.dataLoading = true;
      await ApiService.get("loandetails", `?loancode=${this.loancode}`)
        .then(res => {
          this.authImmovable = res.data.authImmovable.map(row => ({
            description: row.description,
            itemid: row.itemid,
            name1: row.name1,
            name2: row.name2
          }));
          this.currencies = res.data.currencies.map(row => ({
            currencycode: row.currencycode,
            engname: row.engname,
            monname: row.monname
          }));
          this.mrtType1 = res.data.mrtType1.map(row => ({
            mrtcode: row.mrtcode,
            mrtname: row.mrtname
          }));
          this.mrtType2 = res.data.mrtType2.map(row => ({
            mrtcode: row.mrtcode,
            submrtcode: row.submrtcode,
            submrtname: row.submrtname
          }));
          this.transferReason = res.data.transferReason.map(row => ({
            description: row.description,
            itemid: row.itemid,
            name1: row.name1,
            name2: row.name2
          }));
          this.loanDetails = res.data.loanDetails.map(row => ({
            loancode: row.loancode,
            orgcode: row.orgcode,
            datetopay: row.datetopay,
            amounttopay: row.amounttopay
          }));
          this.loanPerformance = res.data.loanPerformance.map(row => ({
            loancode: row.loancode,
            orgcode: row.orgcode,
            paiddate: row.paiddate,
            paidamount: row.paidamount
          }));
          this.interestDetails = res.data.interestDetails.map(row => ({
            loancode: row.loancode,
            orgcode: row.orgcode,
            datetopay: row.datetopay,
            amounttopay: row.amounttopay
          }));
          this.interestPerformance = res.data.interestPerformance.map(row => ({
            loancode: row.loancode,
            orgcode: row.orgcode,
            paiddate: row.paiddate,
            paidamount: row.paidamount
          }));
          this.customerRelationCitizen = res.data.customerRelationCitizen.map(
            row => ({
              customercode: row.customercode,
              registerno: row.registerno,
              lastname: row.lastname,
              subjectname: row.subjectname,
              stateregisterno: row.stateregisterno,
              loancode: row.loancode
            })
          );
          this.customerRelationOrg = res.data.customerRelationOrg.map(row => ({
            customercode: row.customercode,
            registerno: row.registerno,
            lastname: row.lastname,
            subjectname: row.subjectname,
            stateregisterno: row.stateregisterno,
            loancode: row.loancode
          }));
          this.mortgage = res.data.mortgage.map(row => ({
            uniquenumber: row.uniquenumber,
            mrtname: row.mrtname,
            submrtname: row.submrtname,
            dateofvaluation: row.dateofvaluation,
            limit: row.limit,
            mrtvalue: row.mrtvalue,
            currency: row.currency,
            stateregisterno: row.stateregisterno
          }));
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }
});
